import { create } from '@storybook/theming/create';
import logo from './kardia-logo-white.svg';

// https://storybook.js.org/docs/react/configure/theming
export default create({
  base: 'light',
  // Typography
  fontBase: '"Open Sans", sans-serif',
  fontCode: 'monospace',

  // Brand
  brandTitle: 'Kardia by Vena',
  brandUrl: 'https://example.com',
  brandImage: logo,
  brandTarget: '_self',

  // UI
  colorPrimary: '#225D38',
  colorSecondary: '#418154',
  appContentBg: '#ffffff',
  textColor: '#2D2A26',

  // Canvas Addons Bar
  appBg: '#ffffff',
  appBorderRadius: 3,
  appBorderColor: '#D9D8D6',
  barSelectedColor: '#418154',
  booleanBg: '#E7FDF1',
  buttonBg: '#E7FDF1',
  buttonBorder: '#225D38',
});
